import React, { useEffect, lazy, Suspense, memo, useContext, useRef } from 'react';
import {
    SttTabsV2,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { temPermissaoRBAC } from '@src/security/acl';
import { PERMISSOES } from '@src/common/Constants';
import usuario from '@src/signals/usuario';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { idFuncionarioTriagem } from '@src/signals/triagem';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    divWrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));

const PesquisaFuncionarioSuspense = memo((props) => {
    const PesquisaFuncionario = lazy(() => import('../../../componentes/interno/pesquisa/funcionario'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <PesquisaFuncionario {...props} />
            </Suspense>
        </div>
    )
});

const TriagemAutoCadastroSuspense = memo((props) => {
    const TriagemAutoCadastro = lazy(() => import('../../../componentes/interno/pesquisa/triagem'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <TriagemAutoCadastro {...props} />
            </Suspense>
        </div>
    )
});

const IndexSuporte = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');
    const setAbaAtivaRef = useRef();

    useSignalEffect(() => {
        if (idFuncionarioTriagem.value) {
            setAbaAtivaRef.current && setAbaAtivaRef.current.handleChange(null, '0');
        }
    });

    useEffect(() => {
        let dadosAbas = [];

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_FUNCIONARIO)) {
            dadosAbas.push({
                titulo: strings.usuarios,
                conteudo: PesquisaFuncionarioSuspense,
                key: 'PesquisaFuncionarioSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.TRIAGEM_CADASTRO)) {
            dadosAbas.push({
                titulo: strings.triagem,
                conteudo: TriagemAutoCadastroSuspense,
                key: 'TriagemAutoCadastroSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        abas.value = dadosAbas;
    }, []);

    return (
        <SttTabsV2 abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            ref={setAbaAtivaRef}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(IndexSuporte);