import React, { useEffect, lazy, Suspense, memo, useContext } from 'react';
import {
    SttTabsV2,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { temPermissaoRBAC } from '@src/security/acl';
import { PERMISSOES } from '@src/common/Constants';
import usuario from '@src/signals/usuario';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1)
    },
    divWrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));

const InstituicaoSuspense = memo((props) => {
    const Instituicao = lazy(() => import('../../../componentes/interno/pesquisa/instituicao'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <Instituicao {...props} />
            </Suspense>
        </div>
    )
});

const EquipeSuspense = memo((props) => {
    const Equipe = lazy(() => import('../../../componentes/interno/pesquisa/equipes'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <Equipe {...props} />
            </Suspense>
        </div>
    )
});

const PerfisSuspense = memo((props) => {
    const Perfis = lazy(() => import('../../../componentes/interno/pesquisa/perfil'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <Perfis {...props} />
            </Suspense>
        </div>
    )
});

const PermissoesSuspense = memo((props) => {
    const Permissoes = lazy(() => import('../../../componentes/interno/cadastro/permissao'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <Permissoes {...props} />
            </Suspense>
        </div>
    )
});

const PacienteSuspense = memo((props) => {
    const Paciente = lazy(() => import('../../../componentes/interno/pesquisa/paciente'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <Paciente {...props} />
            </Suspense>
        </div>
    )
});

const PacsSuspense = memo((props) => {
    const PACS = lazy(() => import('../../../componentes/interno/pesquisa/pacs'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <PACS {...props} />
            </Suspense>
        </div>
    )
});

const LogFuncionarioSuspense = memo((props) => {
    const LogFuncionario = lazy(() => import('../../../componentes/interno/pesquisa/log-funcionario'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <LogFuncionario {...props} />
            </Suspense>
        </div>
    )
});

const LogUsuarioSuspense = memo((props) => {
    const LogUsuario = lazy(() => import('../../../componentes/interno/pesquisa/log-usuario'));

    return (
        <div className={props.className}>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <LogUsuario {...props} />
            </Suspense>
        </div>
    )
});

// const LiberacaoInstitucionalSuspense = memo((props) => {
//     const LiberacaoInstitucional = lazy(() => import('../../../componentes/interno/pesquisa/acesso-instituicao'));

//     return (
// <div className={props.className}>
//         <Suspense fallback={<SttCircularProgress color="primary" />}>
//             <LiberacaoInstitucional {...props} />
//         </Suspense>
// </div>
//     )
// });

const IndexSuporte = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useEffect(() => {
        let dadosAbas = [];

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISAR_INST)) {
            dadosAbas.push({
                titulo: strings.instituicoes,
                conteudo: InstituicaoSuspense,
                key: 'InstituicaoSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_EQUIPES)) {
            dadosAbas.push({
                titulo: strings.equipes,
                conteudo: EquipeSuspense,
                key: 'EquipeSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_PERFIL)) {
            dadosAbas.push({
                titulo: strings.perfis,
                conteudo: PerfisSuspense,
                key: 'PerfisSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PERMISSOES)) {
            dadosAbas.push({
                titulo: strings.permissoes,
                conteudo: PermissoesSuspense,
                key: 'PermissoesSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISAR_PACIENTE)) {
            dadosAbas.push({
                titulo: strings.paciente,
                conteudo: PacienteSuspense,
                key: 'PacienteSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISAR_PACS)) {
            dadosAbas.push({
                titulo: strings.pacs,
                conteudo: PacsSuspense,
                key: 'PacsSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        // if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_TRIAGEM_ACESSO_INSTITUICAO)) {
        //     dadosAbas.push({
        //         titulo: strings.liberacaoInstitucional,
        //         conteudo: LiberacaoInstitucionalSuspense,
        //         key: 'LiberacaoInstitucionalSuspense',
        //         permanente: true,
        //         className: classes.divWrapper
        //     });
        // }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISAR_LOG_FUNCIONARIO)) {
            dadosAbas.push({
                titulo: strings.logFuncionario,
                conteudo: LogFuncionarioSuspense,
                key: 'LogFuncionarioSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISAR_LOG_USUARIO)) {
            dadosAbas.push({
                titulo: strings.logUsuario,
                conteudo: LogUsuarioSuspense,
                key: 'LogUsuarioSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        abas.value = dadosAbas;
    }, []);

    return (
        <SttTabsV2 abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(IndexSuporte);