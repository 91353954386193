/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getHeaders } from '@src/request';
import {
    SttHeaderV2,
    SttTranslateHook,
    SttSplashScreen,
    MateriaisSuporte,
    SttNotificationScreen,
    SttHelpdeskShortcut
} from '@stt-componentes/core';
import CnesCadsus from './pesquisa/cnes-cadsus';
import { temPermissaoRBAC } from '@security/acl';
import { PERMISSOES } from '@common/Constants';
import usuario from '@src/signals/usuario';

const Menu = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [openCnesCadsus, setOpenCnesCadsus] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    useEffect(() => {
        let menuOptions = [];

        if (usuario.value.permissoesRBAC) {
            if (!temPermissaoRBAC(usuario.value, PERMISSOES.ADMINISTRATIVO)) {
                navigate("/nao-autorizado");
                return;
            }

            setExibirNotificacao(true);

            // if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_MENSAGEM)) {
            //     listaMenuPesquisa.push({
            //         text: 'Mensagens',
            //         onClick: () => {
            //             if (location.pathname !== '/pesquisa/mensagens') {
            //                 navigate(`/pesquisa/mensagens`);
            //             }
            //         },
            //     });
            // if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_FUNCIONARIO)) {
            //     listaMenuPesquisa.push(
            //         {
            //             text: 'Triagem de acesso a instituições',
            //             onClick: () => {
            //                 if (location.pathname !== '/pesquisa/acesso-instituicao') {
            //                     navigate(`/pesquisa/acesso-instituicao`);
            //                 }
            //             }
            //         }
            //     );
            // 
            // if (temPermissaoRBAC(usuario.value, PERMISSOES.CADASTRO_MENSAGEM)) {
            //     listaMenuCadastro.push({
            //         text: 'Mensagens',
            //         onClick: () => {
            //             if (location.pathname !== '/cadastro/mensagens') {
            //                 navigate(`/cadastro/mensagens`);
            //             }
            //         },
            //     });
            // }

            menuOptions = [
                {
                    id: '/suporte',
                    text: strings.suporte,
                    onClick: () => {
                        navigate(`/suporte`);
                    },
                    show: temPermissaoRBAC(usuario.value, [
                        PERMISSOES.PESQUISA_FUNCIONARIO,
                        PERMISSOES.TRIAGEM_CADASTRO
                    ])
                },
                {
                    id: '/admin',
                    text: strings.administrativo,
                    onClick: () => {
                        navigate(`/admin`);
                    },
                    show: temPermissaoRBAC(usuario.value, [
                        PERMISSOES.PESQUISAR_INST,
                        PERMISSOES.PESQUISA_EQUIPES,
                        PERMISSOES.PESQUISA_PERFIL,
                        PERMISSOES.PERMISSOES,
                        PERMISSOES.PESQUISAR_PACIENTE,
                        PERMISSOES.PESQUISAR_PACS,
                    ])
                },
                {
                    id: '/comunicacao',
                    text: strings.comunicacao,
                    onClick: () => {
                        navigate(`/comunicacao`);
                    },
                    show: temPermissaoRBAC(usuario.value, [
                        PERMISSOES.PESQUISA_MATERIAIS_SUPORTE
                    ])
                },
                {
                    id: '/telediagnostico',
                    text: strings.telediagnostico,
                    onClick: () => {
                        navigate(`/telediagnostico`);
                    },
                    show: temPermissaoRBAC(usuario.value, [
                        PERMISSOES.CADASTRO_CIDADE_REF,
                        PERMISSOES.PESQUISA_MOTIVOS_INVALIDACAO,
                        PERMISSOES.PESQUISA_PONTO,
                        PERMISSOES.PESQUISA_REDE_TELEMEDICINA
                    ])
                },
                {
                    text: strings.cnesCadsus,
                    onClick: () => setOpenCnesCadsus(true),
                    show: temPermissaoRBAC(usuario.value, PERMISSOES.ACESSO_CNES_CADSUS)
                }
            ];
        }

        menuOptions = menuOptions.filter(m => m.show);

        setSubmenus(menuOptions);

        menuOptions.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [usuario.value.permissoesRBAC]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [submenus, location.pathname]);

    return (
        <div>
            <SttHeaderV2
                nomeUsuario={usuario.value?.nome || ''}
                config={global.gConfig}
                strings={strings}
                headers={getHeaders()}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS((o) => !o),
                    onClickMateriaisSuporte: () => setOpenMS((o) => !o),
                }}
                submenus={submenus}
                selectedTab={tabAtual}
            />

            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                perfisRBAC={usuario.value.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <CnesCadsus open={openCnesCadsus} setOpen={setOpenCnesCadsus} />
            <MateriaisSuporte strings={strings} config={global.gConfig} headers={getHeaders()} open={openMS} setOpen={setOpenMS} />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={usuario.value}
            />
        </div>
    );
};

export default Menu;
